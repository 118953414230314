<template>
    <b-modal
    :id="$route.name + 'CreateModal'"
    title="Добавить автомобиль"
    size="lg"
    hide-footer no-enforce-focus
    :no-close-on-backdrop="true"
    @shown="detectStartTime"
    @hidden="detectEndTime">
        <b-row class="d-flex justify-content-center flex-column">
            <b-col cols="12">
                <b-row>
                    <b-col cols="4">
                        <label for="">Подразделение: <i class="required">*</i></label>
                        <v-select
                        v-model="cars.division_id" 
                        :reduce="option => option.id"
                        placeholder="Подразделение"
                        label="name" 
                        :options="divisions" 
                        class="select-size-md"
                        />
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12" class="p-1 m-1 border-color">
                <div class="d-flex justify-content-between">
                    <h4><strong>Технические характеристики:</strong></h4>
                    <h5 style="margin-right: 80px;"><strong>Для грузовых автомобилей:</strong></h5>
                </div>
                <b-row>
                    <b-col cols="4" class="pr-1 border-right">
                        <label for="">Категория <i class="required">*</i></label>
                        <v-select
                            v-model="cars.category_car_id"
                            :reduce="car_categorie => car_categorie.id"
                            placeholder="Категория"
                            @input="getCarData()"
                            label="name"
                            :options="car_categories"
                            class="select-size-md"
                        >
                        <template #no-options>
                              <span>
                                Данные отсутствуют!
                              </span>
                            </template>
                        </v-select>
                        <label for="">Марка <i class="required">*</i></label>
                        <v-select
                            v-model="car_brand_id"
                            :reduce="car_brand => car_brand.id"
                            placeholder="Марка"
                            :disabled="condition"
                            label="name"
                            :options="car_brands"
                            class="select-size-md"
                            @input="searchModel()"
                        >
                            <template #no-options>
                              <span>
                                Данные отсутствуют!
                              </span>
                            </template>
                        </v-select>
                        <label for="">Модель<i class="required">*</i></label>
                        <v-select
                            v-model="car_model_select"
                            placeholder="Модель"
                            :disabled="condition2"
                            label="car_model"
                            :options="car_models"
                            class="select-size-md"
                            @input="setSelected()"
                        >
                            <template #no-options>
                              <span>
                                Данные отсутствуют!
                              </span>
                            </template>
                        </v-select>
                        <label for="">Тип кузова</label>
                        <v-select
                            :disabled="condition"
                            v-model.number="cars.body_type_id"
                            :reduce="body_type => body_type.id"
                            placeholder="Тип кузова"
                            label="name"
                            :options="body_types"
                            class="select-size-md"
                        >
                            <template #no-options>
                              <span>
                                Данные отсутствуют!
                              </span>
                            </template>
                        </v-select>
                        <label for="">Тип топлива</label>
                        <v-select
                            :reduce="options => options.id"
                            v-model="cars.fuel_type_id"
                            placeholder="Тип топлива"
                            label="name"
                            :options="fuel_types"
                            class="select-size-md"
                        >
                            <template #no-options>
                              <span>
                                Данные отсутствуют!
                              </span>
                            </template>
                        </v-select>
                        <label for="">Год выпуска <i class="required">*</i></label>
                        <b-input v-model="cars.year_of_issue" placeholder="2022" v-mask="'####'"/>
                    </b-col>
                    <b-col cols="4" class="pl-1 border-right">
                        <label for="">Цвет <i class="required">*</i></label>
                        <v-select
                            v-model="cars.color_id"
                            :reduce="car_color => car_color.id"
                            placeholder="Цвет"
                            label="name"
                            :options="car_colors"
                            class="select-size-md"
                        />
                        <label for="">Состояние <i class="required">*</i></label>
                        <v-select
                            v-model="cars.condition_id"
                            :reduce="car_condition => car_condition.id"
                            placeholder="Состояние"
                            label="name"
                            :options="car_conditions"
                            class="select-size-md"
                        />
                        <label for="">Колличество мест <i class="required">*</i></label>
                        <b-input v-model="cars.count_seat" placeholder="4" :style="cars.count_seat > max_count_seat && max_count_seat != '' ? 'border: 1px solid red;' : ''"/>
                            <template v-if="cars.count_seat > max_count_seat && max_count_seat != ''">
                                <small class="required" style="font-size: 10px;">Колличество пассажирских мест данной марки не может превышать {{max_count_seat}}</small> <br>
                            </template>
                            <label for="">Гос. номер <i class="required">*</i></label>
                            <b-input v-model="cars.car_number" placeholder="1111AA01" v-mask="'####AA##'" @input="checkNumber()"/>
                    </b-col>
                    <b-col>
                        <label for="">Грузоподъемность (т) <i class="required">*</i></label>
                        <b-input :disabled="param" :style="`${param ? 'cursor: not-allowed;': ''}`" v-model="cargo_properties.carrying_capacity" placeholder="" />
                        <label for="">Длина (м)</label>
                        <b-input :disabled="param" :style="`${param ? 'cursor: not-allowed;': ''}`" v-model="cargo_properties.length" placeholder="" />
                        <label for="">Ширина (м)</label>
                        <b-input :disabled="param" :style="`${param ? 'cursor: not-allowed;': ''}`" v-model="cargo_properties.width" placeholder="" />
                        <label for="">Высота (м)</label>
                        <b-input :disabled="param" :style="`${param ? 'cursor: not-allowed;': ''}`" v-model="cargo_properties.height" placeholder="" />
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12" class="p-1 m-1 border-color">
                <h4><strong> Дополнительные опции: </strong></h4>
                <div class="d-flex">
                    <b-form-group class="m-0" style="column-count: 3; width: 100%;">
                      <b-form-checkbox
                        v-for="option in dop_options"
                        :key="option.id"
                        v-model="selected"
                        :value="option.id"
                        name="flavour-3a"
                        style="margin-top: 2px;"
                      >
                        {{ option.name }}
                      </b-form-checkbox>
                    </b-form-group>
                </div>
            </b-col>
            <b-col cols="12" class="p-1 m-1 border-color">
                <h4><strong> Дополнительная информация: </strong></h4>
                <b-form-textarea
                v-model="cars.dop_info"
                placeholder="Введите текст"
                >
                </b-form-textarea>
            </b-col>
        </b-row>
            <b-row class="mt-2 mb-1">
                <b-col class="d-flex justify-content-end">
                    <b-button
                    :disabled="submitButtonDisabled"
                    @click="createCar()"
                    variant="primary">Добавить</b-button>
                    <b-button @click="closeModal()" class="ml-1 mr-1" variant="danger">Отмена</b-button>
                </b-col>
            </b-row>
            <b-modal id="numberValidate" centered :no-close-on-backdrop="true" ok-only>
            <div>
                {{info}}
            </div>
        </b-modal>
    </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import { mask } from "vue-the-mask"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        vSelect,
        ToastificationContent,
    },
    directives: { mask },
    props: ['fuel_types'],
    data(){
        return {
            body_types: [],
            car_brand_id: null,
            cars: {
                division_id: '',
                model_car_id: '',
                category_car_id: 2,
                color_id: '',
                condition_id: '',   
                body_type_id: '',
                year_of_issue: '',
                car_number: '',
                count_seat: '',
                cargo_properties: '',
                dop_options: [],
                dop_info: undefined,
                fuel_type_id: null,
            },
            car_categories: [],
            car_models: [],
            car_brands: [],
            formTime: {
                form_name: null,
                start_time: null,
                end_time: null,
            },
            max_count_seat: '',
            car_colors: [],
            selected: [],
            info: '',
            check_number: {
                car_number: ''
            },
            car_conditions: [],
            car_model_select: undefined,
            divisions: [],
            divisionId: '',
            dop_options: [],
            cargo_properties: {
                width: '',
                height: '',
                length: '',
                carrying_capacity: '',
            },
            condition: true,
            submitButtonDisabled: false,
            param: true,
            condition2: true
        }
    },
    mounted(){
        this.getDopOptions()
            this.$http
            .get('directory/cars')
            .then(res => { 
                this.car_categories = res.data.car_categories
                this.car_colors = res.data.car_colors
                this.car_conditions = res.data.car_conditions
            })
            this.$http
            .get('directory/divisions')
            .then(res => {
                this.divisions = res.data
            })
            this.cars.division_id = this.divisionId
    },
    methods: {
        getDopOptions() {
            if(this.cars.category_car_id) {
                this.$http
                .get(`cars/dop-options?category_car_id=${this.cars.category_car_id}`)
                .then(res => {
                    this.dop_options = res.data
                })
            }
        },
        searchModel(){
            if (this.cars.category_car_id != null) {
                let category = {
                    category_car_id: this.cars.category_car_id,
                    car_brand_id: this.car_brand_id
                }
                this.$http
                .post('cars/model-cars', category)
                .then(res =>{
                    this.car_brands = res.data.car_brands
                    this.car_models = res.data.model_cars
                    this.body_types = res.data.body_types
                    this.condition2 = false
                }) 
            }else {
                this.condition2 = true
                this.cars.car_model_id = ''
                this.cars.body_type_id = ''
            }
            if (this.cars.category_car_id == 3) {
                this.param = false
            }
        },
        closeModal(){
            this.condition = true
            this.cars = {
                    division_id: '',
                    car_model_id: '',
                    category_car_id: '',
                    color_id: '',
                    condition_id: '',   
                    body_type_id: '',
                    year_of_issue: '',
                    car_number: '',
                    count_seet: '',
                    cargo_properties: '',
                    dop_info: undefined,
                    fuel_type_id: null,
                }
            this.submitButtonDisabled = false,
            this.param = true
            this.condition2 = true
            this.car_model_select = null
            this.car_brand_id = null
            this.cargo_properties = {
                width: '',
                height: '',
                length: '',
                carrying_capacity: '',
            },
            this.$bvModal.hide(this.$route.name + 'CreateModal')
       },
        createCar(){
            this.cars.dop_info = this.cars.dop_info ? this.cars.dop_info : undefined
            this.cars.body_type_id = this.cars.body_type_id ? this.cars.body_type_id : undefined
            this.submitButtonDisabled = true
            if (this.cars.category_car_id == 3) {
                this.cars.cargo_properties = JSON.stringify(this.cargo_properties)
            }else{
                this.cars.cargo_properties = undefined
            }
            if (this.selected.length > 0) {
                this.cars.dop_options = []
                let options = []
                this.selected.forEach(el => {
                    options.push({car_option_id: el})
                });
                this.cars.dop_options = JSON.stringify(options)
            }else {
                this.cars.dop_options = undefined
            }
            this.$http
                .post('cars', this.cars)
                .then(res => {
                    this.$bvModal.hide(this.$route.name + 'CreateModal')
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Успех!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: `Машина успешно добавлена!`,
                        },
                    })
                    this.closeModal()
                    this.$emit('refresh')
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        detectEndTime() {
            this.formTime.end_time = this.$moment().format('YYYY-MM-DD HH:mm:ss')
            this.$http.post('time-form', this.formTime)
            .then((res) => {
            })
            .catch((err) => {
            }) 
        },
        detectStartTime() {
            this.formTime.form_name = 'carCreateForm'
            this.formTime.start_time = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        },
        getCarData(){
            if (this.cars.category_car_id != null) {
                let category = {
                    category_car_id: this.cars.category_car_id
                }
                this.getDopOptions()
                this.$http
                .post('cars/model-cars', category)
                .then(res =>{
                    this.car_brands = res.data.car_brands
                    this.body_types = res.data.body_types
                    this.condition = false
                }) 
            }else {
                this.condition = true
                this.cars.car_model_id = ''
                this.cars.body_type_id = ''
            }
            if (this.cars.category_car_id == 3) {
                this.param = false
            }
        },
        setSelected(){
            this.cars.model_car_id = this.car_model_select.id
            this.cars.count_seat = this.car_model_select.car_seat_before
            this.max_count_seat = this.car_model_select.car_seat_before
            this.cars.condition_id = 2
        },
        checkNumber() {
            if (this.cars.car_number.length == 8) {
                this.check_number = {
                    car_number: this.cars.car_number
                }
                this.$http
                    .post('cars/search-car', this.check_number)
                    .then(res => {
                        if (res.data) {
                            this.info = `В базе уже есть автомобиль с таким гос. номером.(Подразделение: ${res.data.division}, Статус: ${res.data.active == 0 ? 'Не работает' : 'Работает'}, ID: ${res.data.id})`
                            this.$bvModal.show('numberValidate')
                        }
                    })
            }
        }
    }
}
</script>


<style scoped>
.border-color{
    border: 1px solid #ccc;
    border-radius: 5px;
    width: auto;
}
.required {
    color: red;
}
</style>